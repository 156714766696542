<template>
  <c-step
    stepperGrpCd="SAS_SAFETY_GEAR_STEP_CD"
    :stepRejectItems="stepRejectItems"
    :currentStepCd="currentStepCd"
    :param="popupParam"
    v-model="stepCd"
    @currentStep="currentStep"
    @closePopup="closePopup"
    @setStep="setStep"
   >
  </c-step>
</template>

<script>
export default {
  name: 'safety-gear-request-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        isRequest: 'Y',
        requestGiveInfoId: '',
        stepCd: '',
        plantCd: null,
        giveTypeCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      stepCd: '',
      currentStepCd: '',
      stepDisabledItems: [],
      stepRejectItems: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setStep();
    },
    setStep() {
      if (!this.popupParam.stepCd || this.popupParam.stepCd === 'SSC0000001') {
        this.stepCd = 'SSG0000001'
        this.currentStepCd = 'SSG0000001'
      } else if (this.popupParam.stepCd === 'SSC0000005') {
        this.stepCd = 'SSG0000010'
        this.currentStepCd = 'SSG0000010'
      } else if (this.popupParam.stepCd === 'SSC0000010') {
        this.stepCd = 'SSG0000010'
        this.currentStepCd = 'SSG0000010'
      } else if (this.popupParam.stepCd === 'SSC0000015') {
        this.stepCd = 'SSG0000010'
        this.currentStepCd = 'SSG0000010'
      } 
      // this.stepDisabledItems = [];
      this.stepRejectItems = [];
      // if (this.popupParam.isRequest === 'N' && this.popupParam.requestGiveInfoId) {
      //     this.stepDisabledItems = ['SSG0000001']
      // } 
      if (this.popupParam.isRequest == 'N') {
        this.stepRejectItems = ['SSG0000001'];
      }
    },
    currentStep(step) {
      if (!step || step === 'SSC0000001') {
        this.stepCd = 'SSG0000001'
        this.currentStepCd = 'SSG0000001'
      } else if (step === 'SSC0000005') {
        this.stepCd = 'SSG0000010'
        this.currentStepCd = 'SSG0000010'
      } else if (step === 'SSC0000010') {
        this.stepCd = 'SSG0000010'
        this.currentStepCd = 'SSG0000010'
      } else if (step === 'SSC0000015') {
        this.stepCd = 'SSG0000010'
        this.currentStepCd = 'SSG0000010'
      } 
      this.popupParam.stepCd = step;
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>
