var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-step", {
    attrs: {
      stepperGrpCd: "SAS_SAFETY_GEAR_STEP_CD",
      stepRejectItems: _vm.stepRejectItems,
      currentStepCd: _vm.currentStepCd,
      param: _vm.popupParam,
    },
    on: {
      currentStep: _vm.currentStep,
      closePopup: _vm.closePopup,
      setStep: _vm.setStep,
    },
    model: {
      value: _vm.stepCd,
      callback: function ($$v) {
        _vm.stepCd = $$v
      },
      expression: "stepCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }